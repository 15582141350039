<template>
    <div v-html="$t('HtmlTermsAndConditions')" class="container">        
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            webUrl: process.env.VUE_APP_ITEXON_URL || 'https://itexon.com/',
            officeEmail: process.env.VUE_APP_OFFICE_EMAIL || 'office@itexon.com',
            numberPhone: process.env.VUE_APP_ITEXON_PHONE || '(+34) 951 331 789',
            notificationsAddressItexon: process.env.VUE_APP_ITEXON_NOTIFICATION_ADDRESS || 'Calle Barroso 14, 1º, CP: 29001-Málaga. España',
            socialAddressItexon: process.env.VUE_APP_ITEXON_SOCIAL_ADDRESS || 'Calle Loma de los Riscos 73, B2, 39. C.P. 29620, Torremolinos, Málaga. España',
            itexonName: process.env.VUE_APP_ITEXON_NAME || 'ITEXON SYSTEMS, S.L.'
        }
    },
    computed: { ...mapState(["defaultTitle"]) },
    metaInfo() {
        return {
            title: `${this.$t('footerLink.legalNotice')} - ${this.defaultTitle}`,
            meta: [
                {name: 'og:title', property: 'og:title', content:  `${this.$t('footerLink.legalNotice')} - ${this.defaultTitle}`},
                {name: 'og:type', property: 'og:type', content: 'website'},
                {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
                {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.legalNotice.description')},
                {name: 'description', content: this.$t('vueMeta.legalNotice.description')},
                {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
            ]
        }
    }
}
</script>

<style>
h1, h2, h3,p{
    color: black;    
}
h1, h2, h3,p{
    margin-top: 15px;
    margin-bottom: 15px;
}
.title {
    font-weight: bold;
    display: block;
}
.bold {
    font-weight: bold;
}
</style>